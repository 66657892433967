/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import '../../index.css';

function Navbar() {
	const [menuDisplay, setMenuDisplay] = useState(
		{display: "block"}
	);

	const [closeDisplay, setCloseDisplay] = useState(
		{display: "none"}
	);

	const [navDisplay, setNavDisplay] = useState(
		{display: "none"}
	);

	const menuStyle = {
		displays: {menuDisplay}
	};

	const closeStyle = {
		displays: {closeDisplay}
	}

	const navStyle = {
		displays: {navDisplay}
	}

	const clickMenu = () => {
		// Mobile nav open
		setMenuDisplay(
			{
				display: "none"
			}
		);
		setCloseDisplay(
			{
				display: "block"
			}
		);
		setNavDisplay(
			{
				display: "flex"
			}
		)
	};

	const clickClose = () => {
		// Mobile nav close
		setMenuDisplay(
			{
				display: "block"
			}
		);
		setCloseDisplay(
			{
				display: "none"
			}
		);
		setNavDisplay(
			{
				display: "none"
			}
		)
	};
	return(
		<div id="navbar" className="row">
			<div className="col-12 p-0">
				<div className="navBar">
					<div className="navBrand">
						<Link to ="/">
							<img src="https://bryandoconnor.s3-us-west-2.amazonaws.com/images/Brand.svg" alt="Brand logo" />
						</Link>
					</div>
					<div className="nav-wrapper">
						<div className="nav">
							<Link to ="/" className="navLink">Home</Link>
							<Link to ="/about" className="navLink">About</Link>
							<Link to ="/experience" className="navLink">Experience</Link>
							<Link to ="/contact" className="navLink">Contact</Link>
						</div>
					</div>
				</div>
				<div className="navBar-mobile">
					<div className="navBrand">
						<Link to ="/">
							<img src="https://bryandoconnor.s3-us-west-2.amazonaws.com/images/Brand.svg" alt="Brand logo" />
						</Link>
					</div>
					<div style={menuStyle.displays.menuDisplay}>
						<div className="navHamburger" onClick={clickMenu}>Menu</div>
					</div>
					<div style={closeStyle.displays.closeDisplay}>
						<div className="navHamburger" onClick={clickClose}>X</div>
					</div>
					<div className="nav-mobile w-100" style={navStyle.displays.navDisplay}>
						<Link to ="/" className="navLink">Home</Link>
						<Link to ="/software" className="navLink">Software Services</Link>
						<Link to ="/design" className="navLink">Design Services</Link>
						<Link to ="/experience" className="navLink">Experience</Link>
						<Link to ="/contact" className="navLink">Contact</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
