/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faProjectDiagram, faUserTie, faCodeBranch } from '@fortawesome/free-solid-svg-icons';

function AboutHero() {
	return (
		<div>
			<div className="DesignHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Bryan O'Connor</h1>
				<h1 className="montserrat-font hero-banner-text orange-text">Principal Software Engineer</h1>
			</div>
		</div>
	);
}

function AboutContent() {
	return (
		<div>
			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faCogs} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Practical Engineering, Built for Scale</h1>
						<p className="xlg-paragraph">
							I've worked across startups, enterprises, and consulting contracts—always focused on delivering software that’s clean, scalable, and aligned with real-world needs. 
							My specialty is building backend systems and distributed architectures that are easy to maintain and built to last.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin xl-padding">
					<div className="m-0 py-3 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faProjectDiagram} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Strategic Contributor</h1>
							<p className="xlg-paragraph">
								I'm comfortable working solo or as part of a high-performing team, and I’ve often been the go-to person to bring clarity to complex technical challenges.
								Whether architecting new systems, collaborating across teams, or mentoring devs, I operate with calm execution and business-aligned priorities.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faUserTie} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Team-Oriented Leadership</h1>
						<p className="xlg-paragraph">
							My leadership style is grounded in accountability, technical clarity, and collaboration. I focus on supporting the people doing the work, improving the systems they rely on, 
							and helping teams deliver consistently. Whether mentoring, guiding architecture, or unblocking roadblocks, I lead with purpose and stability.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin mb-0 xl-padding">
					<div className="m-0 py-3 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faCodeBranch} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Open to New Opportunities</h1>
							<p className="xlg-paragraph">
							I’m open to joining a team that values strong engineering, clear direction, and steady execution.
							I'm currently open to full-time roles focused on engineering leadership and backend systems architecture.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function About() {
	return (
		<div className="container-fluid p-0">
			<AboutHero />
			<AboutContent />
		</div>
	);
}

export default About;
