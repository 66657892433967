/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faGlobe, faPeopleGroup, faCode, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

function ExperienceHero() {
	return (
		<div>
			<div className="ExperienceHero Hero">
				<h1 className="hero-banner-text montserrat-font white-text">Professional</h1>
				<h1 className="dancing-font hero-banner-text orange-text">Experience</h1>
			</div>
		</div>
	);
}

function ExperienceContent() {
	return (
		<div>
			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faHandshake} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Various Clients & Companies</h1>
						<p className="xlg-paragraph">
							I've been working in software and web development for over 14 years, with experience in frontend, backend and machine learning 
							development as well as team and product leadership roles. I've worked on a variety of projects, from small startups to large 
							enterprise applications, and have experience in different industries, including finance, healthcare, and e-commerce.
						</p>
					</div>
				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin xl-padding">
					<div className="m-0 py-3 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faGlobe} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Professional Experience</h1>
							<div className="xlg-paragraph">
								<div className="text-left">I have had the privilege of working with an array of leading organizations, including:</div>
								<ul className="text-left">
									<li><span className="font-blue">Zions Bancorporation:</span> Developing proprietary treasury applications to streamline operations.</li>
									<li><span className="font-blue">Kin + Carta:</span> Serving as Principal Engineer, driving engineering projects and mentoring teams.</li>
									<li><span className="font-blue">Vivint:</span> Leading development efforts for innovative smart home solutions.</li>
									<li><span className="font-blue">Cisco:</span> Creating secure, scalable systems tailored to enterprise needs.</li>
									<li><span className="font-blue">Ascential:</span> Developing scalable e-commerce platforms.</li>
								</ul>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="lg-margin xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faPeopleGroup} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Leadership & Team Building</h1>
						<div className="xlg-paragraph">
							<div className="text-left">My leadership journey includes guiding teams to success and cultivating environments where innovation thrives:</div>
							<ul className="text-left">
								<li>Led a team that won the Gold Stevie Award for Best Technical Support Strategy in 2023.</li>
								<li>Experience mentoring engineers at all levels, building cohesive teams aligned with organizational goals.</li>
								<li>Experience collaborating with architects, product owners, and stakeholders to ensure project alignment and timely delivery.</li>
							</ul>
						</div>
					</div>

				</div>
			</div>

			<div className="gray-bg">
				<div className="lg-margin xl-padding">
					<div className="m-0 py-3 row">
						<div className="col-12 col-md-3 p-0 text-center text-md-right">
							<div className="d-inline-block mr-0 mr-md-4">
								<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faCode} /></span></div>
							</div>
						</div>
						<div className="col-12 col-md-9 p-0 text-center text-md-left">
							<h1 className="main-heading montserrat-font">Technical Expertise</h1>
							<div className="xlg-paragraph">
								<div className="text-left">My technical toolkit encompasses a wide array of technologies and platforms, enabling me to tackle diverse challenges:</div>
								<ul className="text-left">
									<li><span className="font-blue">Frontend:</span> React, TypeScript, JavaScript, HTML, CSS, Sass.</li>
									<li><span className="font-blue">Backend:</span> Node.js, Express, .NET, Java, Spring Boot.</li>
									<li><span className="font-blue">Cloud:</span> AWS, GCP, and cloud-native solutions.</li>
									<li><span className="font-blue">AI Integration:</span> Hands-on experience implementing AI into client solutions for automation and analytics.</li>
									<li><span className="font-blue">APIs:</span> Designed and implemented robust, custom APIs for high-traffic applications.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="lg-margin mb-0 xl-padding">
				<div className="m-0 py-3 row">
					<div className="col-12 col-md-3 p-0 text-center text-md-right">
						<div className="d-inline-block mr-0 mr-md-4">
							<div className="circle-icon-container"><span className="circle-icon"><FontAwesomeIcon icon={faFlagCheckered} /></span></div>
						</div>
					</div>
					<div className="col-12 col-md-9 p-0 text-center text-md-left">
						<h1 className="main-heading montserrat-font">Proven Track Record</h1>
						<div className="xlg-paragraph">
							<div className="text-left">I’ve consistently delivered results that make a difference:</div>
							<ul className="text-left">
								<li>Enhanced operational efficiency through custom solutions at Zions Bancorporation.</li>
								<li>Delivered scalable, high-performing e-commerce platforms for Ascential.</li>
								<li>Blended technical expertise with design leadership at Alphagraphics, crafting innovative marketing solutions.</li>
								<li>Collaborated with M Graphics and Signs Inc. to integrate design and technology into client deliverables.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

function Experience() {
	return (
		<div className="container-fluid p-0">
			<ExperienceHero />
			<ExperienceContent />
		</div>
	);
}

export default Experience;